



























































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator'
import TextAreaWithOneModeText from '@/components/TextAreaWithOneModeText.vue'
import TopicDetailComment from '@/views/Group/TopPage/NewFeed/TopicDetailComment.vue'
import ModalTopicSelectedFile from '@/components/Modal/ModalTopicSelectedFile.vue'
import { EEditMode, ETinyFileType, TopicCommentParams } from '@/models'

@Component({
  components: { TextAreaWithOneModeText, TopicDetailComment, ModalTopicSelectedFile }
})
export default class TopicCommentArea extends Vue {
  @Prop() private maxFileSize: any
  @Prop() private title?: string
  @Prop() private titleSuffix?: string
  @Prop() private isCommentCmt?: boolean
  @Prop() private textAreaId?: string
  private newComment: TopicCommentParams = new TopicCommentParams()
  private commentFileName: string = String(this.$t('common.form.not_selected'))
  private eTinyFileType = ETinyFileType
  private modalMess: string = ''
  private valid: boolean = true

  @Watch('newComment.attach_file')
  @Watch('newComment.content_comment')
  handleTypingStatus() {
    this.$emit(
      'status',
      !!this.newComment.content_comment.length ||
        !!this.newComment.attach_file.length
    )
  }

  handleValueComment(value: any) {
    if (value.mode === EEditMode.TEXT) {
      this.newComment.content_comment = value.text
    } else {
      this.newComment.content_comment = value.html
    }
    this.newComment.content_comment_text = value.text
    this.newComment.tiny_file_ids = value.fileIds
  }

  handleValidContent(valid: boolean) {
    this.valid = valid
  }

  hanldeNewComment() {
    if (
      (this.newComment.content_comment.trim() && this.valid) ||
      this.newComment.attach_file.length
    ) {
      this.$emit('submit', this.newComment)
    }
  }

  clearComment() {
    this.newComment = new TopicCommentParams()
    this.commentFileName = String(this.$t('common.form.not_selected'))
  }

  /**
   * Watch files choose to handle decriptions
   */
  @Watch('newComment.attach_file')
  handleChooseFilesName() {
    let name = this.$t('common.form.multi_selected', {
      count: this.newComment.attach_file.length
    }) as string
    if (this.newComment.attach_file.length === 0) {
      name = this.$t('common.form.not_selected') as string
      this.$bvModal.hide(`modal-slelected-file-comment-area-${this.title}`)
    }
    if (this.newComment.attach_file.length === 1) {
      name = this.newComment.attach_file[0].name
    }
    this.commentFileName = name
  }

  /**
   * Handle choose multiple file
   */
  onCommentFileChange(event: any) {
    const files: File[] = event.target.files || event.dataTransfer.files
    if (!files.length) return
    if (this.newComment.attach_file.length + files.length > 30) {
      this.modalMess = this.$t('common.message.over_30_file') as string
      this.$bvModal.show(`modal-info-topic-comment-area-${this.title}`)
      return
    }
    let fileterFile: File[] = []
    let haveSomeFileOverSize: boolean = false
    files.forEach(item => {
      if (item.size > this.maxFileSize.bytes) {
        haveSomeFileOverSize = true
      } else {
        fileterFile.push(item)
      }
    })
    if (haveSomeFileOverSize) {
      this.modalMess = this.$t('common.form.some_file_over_size', {
        max: this.maxFileSize.mb
      }) as string
      this.$bvModal.show(`modal-info-topic-comment-area-${this.title}`)
    }
    this.newComment.attach_file = [
      ...this.newComment.attach_file,
      ...fileterFile
    ]
  }

  /**
   * Open modal to display all choosen files
   */
  openDisplayFiles() {
    if (this.newComment.attach_file.length)
      this.$bvModal.show(`modal-slelected-file-comment-area-${this.title}`)
  }

  /**
   * Remove file by click 'x' in files display modal
   */
  removeFileFromFilesModal(index: number) {
    this.newComment.attach_file = this.newComment.attach_file.filter(
      (item: any, i: number) => i !== index
    )
  }
}
