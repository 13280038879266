










































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TopicDetailComment from '@/views/Group/TopPage/NewFeed/TopicDetailComment.vue'
import TopicCommentArea from '@/views/Group/TopPage/NewFeed/TopicCommentArea.vue'
import ModalLikers from '@/components/Modal/ModalLikers.vue'
import {
  Topic,
  TopicLiker,
  EFileFormat,
  TopicAttachFile,
  EUserStatus
} from '@/models'
import CheckFileFormat from '@/helpers/CheckFileExtension'
import GroupTopicService from '@/services/GroupTopicService'
import CommonTopicService from '@/services/CommonTopicService'
import GroupService from '@/services/GroupService'
import CommonSharedFolderService from '@/services/CommonSharedFolderService'
import PreviewText from '@/helpers/PreviewText'
import CheckDevice from '@/helpers/CheckDevice'
import {
  getCommonTopicCount,
  triggerFavorite
} from '@/helpers/GetNotificationCount'

import { getModule } from 'vuex-module-decorators'
import CommonTopic from '@/store/modules/CommonTopic'
import store from '@/store'
import { EhumbNailsDisplay } from '@/models/Setting/Enum'

const CommonTopicModule = getModule(CommonTopic, store)
/**
 * f2-405
 * f2-405a
 * サークル掲示板のトピック詳細
 * サークル掲示板のトピック詳細　いいね・コメント等
 *
 * f2-702
 * f2-702a
 * 共有トピックのトピック詳細
 * 共有トピックのトピック詳細　いいね・コメント等
 */
@Component({
  components: {
    TopicDetailComment,
    TopicCommentArea,
    ModalLikers
  }
})
export default class TopicDetail extends Vue {
  //check that this page is common topic or group
  private isCommonTopic = !this.$route.params.groupId
  private isDraftTopic: boolean = true
  private roleEditTopic: boolean = false
  private currentTopic: Topic = new Topic()
  private eFileFormat = EFileFormat
  private modalMess: string = ''
  private modalAction: any = () => {}
  private delCommentId: number = -1
  private maxLikerDisplay: number = 10
  private fullOverviewSrc: string = ''
  private noAvatar = require('@/assets/images/avatar-default.png')
  private totalComment: number = 0
  private is404Error: boolean = false
  private confirmLeaveIgnore: boolean = true
  private confirmLeave: any = () => {}
  private eUserStatus = EUserStatus
  private displayThumbnail: boolean =
    this.$store.state.setting.setting.setting_display.display_image_video ===
    EhumbNailsDisplay.DISPLAY_THUMBNAILS

  @Watch('$route.params.topicId')
  async init() {
    await this.getCurrentTopic()
    this.handleRoleEditTopic()
    this.handleMarkRead()
    this.resizeIFrameToFitContent()
    this.resizeITableToFitContent()
  }

  created() {
    this.init()
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    this.handleMarkRead()

    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (this.confirmLeaveIgnore) {
      next()
    } else {
      this.confirmLeave = next
      this.$bvModal.show('topic-detail-modal-confirm-leave')
    }
  }

  handleConfirmIgnore(ignore: boolean) {
    this.confirmLeaveIgnore = !ignore
  }

  @Watch('$store.state.userInfo.user.group_role')
  handleRoleEditTopic() {
    if (this.isCommonTopic) {
      this.roleEditTopic = false
      // this.currentTopic.creater_id === this.$store.state.userInfo.user.info.id
    } else if (this.currentTopic.topic_detail.is_multiple_edit) {
      this.roleEditTopic = true //every one can edit topic
    } else {
      this.roleEditTopic = this.$store.state.userInfo.user.group_role.role_edit_topic
    }
  }

  resizeIFrameToFitContent() {
    const iframes = document.querySelectorAll('iframe')
    iframes.forEach((iFrame: any) => {
      if (iFrame.clientWidth > document.body.clientWidth) {
        iFrame.parentElement.classList.value = 'ratio-16-9'
        iFrame.classList.value = 'position-absolute w-100 h-100 start-0 top-0'
      }
    })
  }

  resizeITableToFitContent() {
    const tables = document.querySelectorAll('table')
    tables.forEach((table: any) => {
      if (table.clientWidth > document.body.clientWidth) {
        table.style.width = '100%'
      }
    })
  }

  handleMarkRead() {
    if (this.isCommonTopic) {
      CommonTopicService.markAsCommonTopicRead(
        Number(this.$route.params.topicId)
      )
      getCommonTopicCount()
    } else {
      GroupTopicService.markAsRead(
        this.$route.params.groupId,
        Number(this.$route.params.topicId)
      )
    }
    //handle rebind unred count
    CommonTopicModule.SET_RELOAD_UNREAD(true)
  }

  /**
   * Call API get current topic detail
   */
  async getCurrentTopic() {
    if (this.isCommonTopic) {
      return CommonTopicService.getCommonTopicById(this.$route.params.topicId)
        .then(res => {
          if (res.status === 200) {
            this.currentTopic = new Topic(res.data)
            const deliverDate = this.currentTopic.topic_detail.delivery_date
            this.isDraftTopic = this.moment(deliverDate).isAfter(this.moment())
          }
        })
        .catch(err => this.handleTopicNotFound(err))
    } else {
      return GroupTopicService.getTopicById(
        this.$route.params.groupId,
        this.$route.params.topicId
      )
        .then(res => {
          if (res.status === 200) {
            this.currentTopic = new Topic(res.data)
            const deliverDate = this.currentTopic.topic_detail.delivery_date
            this.isDraftTopic = this.moment(deliverDate).isAfter(this.moment())
          }
        })
        .catch(err => this.handleTopicNotFound(err))
    }
  }

  /**
   * Handle topic not found
   */
  handleTopicNotFound(err: any) {
    if (this.is404Error) return
    if (err.response.status === 404) {
      this.is404Error = true
      if (err.response.data.message === 'comment_not_found') {
        this.modalMess = this.$t('common.message.comment_not_exist') as string
        this.modalAction = () =>
          (this.$refs as any).topicDetailComment.getAllComments(1)
      } else {
        this.modalMess = this.$t('common.message.topic_not_exist') as string
        this.modalAction = () => this.jumpToNewFeed()
      }
    } else if (err.response.status === 403) {
      this.modalMess = this.$t('common.message.uncatch_error') as string
      this.modalAction = () => this.jumpToNewFeed()
    } else {
      this.modalMess = this.$t('common.message.uncatch_error') as string
      this.modalAction = () => this.jumpToNewFeed()
    }
    this.$bvModal.show('modal-error-topic-detail')
  }

  /**
   * Handle mark favorite for topic
   * Call API mark fovarite
   */
  handleFavor() {
    if (this.isCommonTopic) {
      CommonTopicService.markAsCommonTopicFavorite(this.$route.params.topicId)
        .then(res => {
          if (res.status === 200) {
            this.currentTopic.is_favor = res.data.is_favorite
            triggerFavorite()
          }
        })
        .catch(err => this.handleTopicNotFound(err))
    } else {
      GroupTopicService.markAsFavorite(
        this.$route.params.groupId,
        this.$route.params.topicId
      )
        .then(res => {
          if (res.status === 200) {
            this.currentTopic.is_favor = res.data.is_favorite
            triggerFavorite()
          }
        })
        .catch(err => this.handleTopicNotFound(err))
    }
  }

  /**
   * Handle like topic
   * Call API like topic
   */
  handleLikeTopic() {
    this.handleAfterLikeTopic()
    if (this.isCommonTopic) {
      CommonTopicService.markAsCommonTopicLike(this.$route.params.topicId)
        .then(res => {
          if (res.status !== 200) {
            this.handleAfterLikeTopic()
          }
        })
        .catch(err => {
          this.handleAfterLikeTopic()
          this.handleTopicNotFound(err)
        })
    } else {
      GroupTopicService.markAsLike(
        this.$route.params.groupId,
        this.$route.params.topicId
      )
        .then(res => {
          if (res.status !== 200) {
            this.handleAfterLikeTopic()
          }
        })
        .catch(err => {
          this.handleAfterLikeTopic()
          this.handleTopicNotFound(err)
        })
    }
  }

  /**
   * Handle after call API like or dislike topic
   */
  handleAfterLikeTopic() {
    const currentUser = this.$store.state.userInfo.user
    this.currentTopic.is_like = !this.currentTopic.is_like
    if (this.currentTopic.is_like) {
      const newLiker = new TopicLiker({
        id: currentUser.info.id,
        liker_name: currentUser.profile.status_company == 'stop' ? '停止された企業様' : currentUser.profile.status_company == 'leave' ? '退会された企業様' : currentUser.profile.nickname,
        avatar: currentUser.profile.avatar_small,
        name_company: currentUser.profile.status_company == 'stop' || currentUser.profile.status_company == 'leave' ? null : currentUser.profile.name_admin_company ? currentUser.profile.name_admin_company : null,
        status_company: currentUser.profile.status_company,
      })
      this.currentTopic.liker = [newLiker, ...this.currentTopic.liker]
      this.currentTopic.liker_count++
    } else {
      const newLikers = this.currentTopic.liker.filter(
        (item: any) => item.id !== currentUser.info.id
      )
      this.currentTopic.liker = newLikers
      this.currentTopic.liker_count--
    }
  }

  catchAddCommentError(err: any) {
    if (err.response.status === 404) {
      if (err.response.data.message === 'topic_not_found') {
        this.modalMess = this.$t('common.message.topic_not_exist') as string
        this.modalAction = () => this.jumpToNewFeed()
        this.$bvModal.show('modal-error-topic-detail')
        return
      } else if (err.response.data.message === 'comment_not_found') {
        this.modalMess = this.$t('common.message.comment_not_exist') as string
        ;(this.$refs as any).topicDetailComment.getAllComments(1)
      }
    } else if (err.response.data.message === 'EXCEED_GROUP_STORAGE_CAPACITY') {
      this.modalMess = this.$t('common.message.group_over_size') as string
    } else if (
      err.response.status === 422 &&
      err.response.data.message === 'MAX_TOTAL_FILE_SIZE'
    ) {
      this.modalMess = this.$t('common.message.upload_over_size', {
        max: err.response.data.upload_max_file_size
      }) as string
      this.modalAction = () => {}
    } else {
      this.modalMess = this.$t('common.message.register_fail') as string
    }
    this.$bvModal.show('modal-error-add-comment')
  }

  setTotalComment(totalComment: number) {
    this.totalComment = totalComment
  }

  /**
   * Open full view for image file
   */
  fullOverview(src: string) {
    this.fullOverviewSrc = src
    this.$bvModal.show('full-image-overview')
  }

  /**
   * Handle open confirm modal
   */
  openConfirmModal(commentId?: number) {
    if (commentId !== undefined) {
      this.delCommentId = commentId
      this.modalMess = this.$t('common.message.delete') as string
      this.$bvModal.show('modal-confirm')
      return
    }
    this.delCommentId = -1
    this.modalMess = this.$t('common.message.move_to_bin') as string
    this.$bvModal.show('modal-confirm')
  }

  /**
   * Do when client click YES on confirm modal
   */
  confirmRemoveOrDelCmt() {
    if (this.delCommentId === -1) {
      this.handleMoveToBin()
      return
    }
    this.handleDelCmt()
  }

  /**
   * Call API to move topic to bin
   */
  handleMoveToBin() {
    this.$blockui.show()
    GroupTopicService.moveTopicsToBin(this.$route.params.groupId, [
      this.currentTopic.id
    ])
      .then(res => {
        if (res.status === 200) {
          this.modalMess = this.$t('common.message.moved_to_bin') as string
          this.modalAction = () => this.jumpToNewFeed()
          this.$bvModal.show('modal-success')
        }
      })
      .catch(err => {
        if (
          err.response.status === 404 &&
          err.response.data.message === 'topic_has_been_deleted'
        ) {
          this.modalMess = this.$t('common.message.topic_not_exist') as string
        } else {
          this.modalMess = this.$t('common.message.move_to_bin_fail') as string
        }
        this.modalAction = () => this.jumpToNewFeed()
        this.$bvModal.show('modal-error-topic-detail')
      })
      .finally(() => this.$blockui.hide())
  }

  /**
   * Call API to delete comment
   */
  handleDelCmt() {
    const commentNotExist = this.$t('common.message.comment_not_exist')
    const topicNotExist = this.$t('common.message.topic_not_exist')
    const deleteFail = this.$t('common.message.delete_fail')
    const deleted = this.$t('common.message.deleted')
    this.$blockui.show()
    if (this.isCommonTopic) {
      CommonTopicService.deleteCommonComment(this.delCommentId)
        .then(res => {
          if (res.status === 200) {
            this.modalMess = deleted as string
            this.modalAction = () =>
              (this.$refs as any).topicDetailComment.getAllComments(1)
            this.$bvModal.show('modal-success')
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            if (err.response.data.message === 'comment_not_found') {
              this.modalMess = commentNotExist as string
              this.modalAction = () =>
                (this.$refs as any).topicDetailComment.getAllComments(1)
            } else if (err.response.data.message === 'topic_not_found') {
              this.modalMess = topicNotExist as string
              this.modalAction = () => this.jumpToNewFeed()
            } else {
              this.modalMess = deleteFail as string
              this.modalAction = () =>
                (this.$refs as any).topicDetailComment.getAllComments(1)
            }
          } else {
            this.modalMess = deleteFail as string
            this.modalAction = () =>
              (this.$refs as any).topicDetailComment.getAllComments(1)
          }
          this.$bvModal.show('modal-error-topic-detail')
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupTopicService.deleteComment(
        this.$route.params.groupId,
        this.delCommentId
      )
        .then(res => {
          if (res.status === 200) {
            this.modalMess = deleted as string
            this.modalAction = () =>
              (this.$refs as any).topicDetailComment.getAllComments(1)
            this.$bvModal.show('modal-success')
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            if (err.response.data.message === 'comment_not_found') {
              this.modalMess = commentNotExist as string
              this.modalAction = () =>
                (this.$refs as any).topicDetailComment.getAllComments(1)
            } else if (err.response.data.message === 'topic_not_found') {
              this.modalMess = topicNotExist as string
              this.modalAction = () => this.jumpToNewFeed()
            } else {
              this.modalMess = deleteFail as string
              this.modalAction = () =>
                (this.$refs as any).topicDetailComment.getAllComments(1)
            }
          } else {
            this.modalMess = deleteFail as string
            this.modalAction = () =>
              (this.$refs as any).topicDetailComment.getAllComments(1)
          }
          this.$bvModal.show('modal-error-topic-detail')
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Jump to edit topic page
   */
  routeToTopicUpdate() {
    if (this.isCommonTopic) {
      return { name: 'common-topic-new-feed-topic-update' }
    }
    return { name: 'group-new-feed-topic-update' }
  }

  /**
   * Jump to new feed page
   */
  jumpToNewFeed() {
    let router: any = { name: 'home' }
    if (this.$route.params.from) {
      router = this.$route.params.from
    } else {
      if (!this.isCommonTopic) {
        if (this.$route.params.from) {
          router = this.$route.params.from
        } else {
          router = {
            name: 'group-new-feed',
            query: {
              folder: this.$route.params.folder
                ? this.$route.params.folder
                : this.currentTopic.is_in_bin
                ? 'bin'
                : 'all'
            }
          }
        }
      } else {
        router = { name: 'common-topic-new-feed' }
      }
    }
    this.$router.push(router)
  }

  /**
   * Format date
   */
  formatDate(date: string) {
    const yyyyymmdd = this.$t('common.date_format.yyyy_mm_dd') as string
    return this.moment(date, yyyyymmdd).format(yyyyymmdd)
  }

  /**
   * Call API to get download files stream
   */
  async downloadFile(file: TopicAttachFile) {
    //handle dowload IOS not safari
    if (
      CheckDevice.iOSBrowerName().toLowerCase() !== CheckDevice.safari &&
      CheckDevice.isMobile() &&
      CheckDevice.getMobileOS() === CheckDevice.ios
    ) {
      window.open(file.path)
      return
    }

    //download other
    this.$blockui.show()
    if (this.isCommonTopic) {
      CommonSharedFolderService.downloadCommonFiles([file.id])
        .then(res => {
          if (res.status === 200) {
            this.hanldeDownloadFile(res, file)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupService.downloadFiles(this.$route.params.groupId, [file.id])
        .then(res => {
          if (res.status === 200) {
            this.hanldeDownloadFile(res, file)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => this.$blockui.hide())
    }
  }

  hanldeDownloadFile(res: any, file: TopicAttachFile) {
    const bytes = new Uint8Array(res.data)
    const blob = new Blob([bytes], { type: '*' })
    let a = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.setAttribute('target', '_blank')
    a.setAttribute('download', file.name)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  handleLongText(text: string, max?: number) {
    return PreviewText.covertToPreviewText(text, max)
  }

  handleUserName(isCreater: boolean) {
    let text = this.hanldeUserStatus(isCreater)
    const preview = PreviewText.covertToPreviewText(text)
    return {
      name: text,
      text: preview.text
    }
  }

  hanldeUserStatus(isCreater: boolean) {
    const name = isCreater
      ? this.currentTopic.creater_name
      : this.currentTopic.updater_name

    const status = isCreater
      ? this.currentTopic.creater_active_status
      : this.currentTopic.updater_active_status

    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }

  handleFileNameWithSize(attachFile: TopicAttachFile) {
    if (!attachFile.size) return this.handleLongText(attachFile.name, 40).text
    const bytes = Number(attachFile.size)
    let sizeText = '0 KB'
    if (bytes) {
      if (bytes < 1) {
        sizeText = '1 KB'
      } else {
        const k = 1024
        const sizes = ['KB', 'MB', 'GB', 'TB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        sizeText =
          parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
      }
    }
    return this.handleLongText(attachFile.name, 40).text + ` (${sizeText})`
  }

  getFileFormat(extension: string) {
    return CheckFileFormat.getFileFormat(extension)
  }

  getFormatIconClass(extension: string) {
    return CheckFileFormat.getIconClassNameWithFileExtention(extension)
  }
}
