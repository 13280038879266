


















































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator'
// import TextEditor from '@/components/TextEditor/TextEditor.vue'
import { EEditMode, ETinyFileType, ITinyEditorData } from '@/models'
import PreviewText from '@/helpers/PreviewText'
import { checkScript } from '@/validations/validation'
//@ts-ignore
import _ from 'lodash'
const emojiRegex = require('emoji-regex')

@Component({ components: {  } })
export default class TextAreaWithOneModeText extends Vue {
  @Prop() private title!: string
  @Prop({ default: '' }) private titleSuffix?: string
  @Prop({ default: 4 }) private areaRows?: number
  @Prop() private defaultValue!: any
  @Prop() private mode?: EEditMode
  @Prop() private tinyFileType!: ETinyFileType //important
  @Prop() private maxFileSize?: any
  @Prop() private textAreaId?: string
  private eEditMode: any = EEditMode
  private selectedMode: EEditMode = EEditMode.TEXT
  private tinyEditorData: ITinyEditorData = {
    html: '',
    text: '',
    fileIds: []
  }

  //validate
  private includesScriptTagContent: boolean = false
  // private includesEmoji: boolean = false
  private overLengthContent: boolean = false
  // private includesEmojiMess: string = this.$t('common.form.no_emoji', {
  //   name: this.$t('common.form.document')
  // }) as string
  private overLengthMess: string = this.$t('common.form.max_length', {
    name: this.$t('common.form.document'),
    length: 20000
  }) as string
  private includesScriptTagMess: string = this.$t('common.form.no_script_tag', {
    name: this.$t('common.form.document')
  }) as string

  @Watch('mode')
  watchMode() {
    this.selectedMode = this.mode ? this.mode : EEditMode.TEXT
  }

  @Watch('defaultValue')
  setValueForTextArea() {
    this.tinyEditorData = {
      html: this.defaultValue.html ? this.defaultValue.html : '',
      text: this.defaultValue.text ? this.defaultValue.text : '',
      fileIds: this.defaultValue.fileIds ? this.defaultValue.fileIds : []
    }
  }

  @Watch('tinyEditorData.html')
  @Watch('tinyEditorData.text')
  @Watch('tinyEditorData.fileIds')
  handleTextChange() {
    this.tinyEditorData.text = this.tinyEditorData.text.split('\t').join('    ')
    this.$emit('getValue', {
      html: this.tinyEditorData.html,
      text: this.tinyEditorData.text,
      fileIds: this.tinyEditorData.fileIds,
      mode: this.selectedMode
    })
  }

  /**
   * Validation
   *
   * Check content
   */
  @Watch('tinyEditorData.text')
  @Watch('selectedMode')
  handleContentError() {
    // this.includesEmoji =
    //   this.selectedMode === EEditMode.TEXT &&
    //   emojiRegex().test(this.tinyEditorData.text)
    this.overLengthContent =
      new Blob([this.tinyEditorData.text]).size > 80000 ||
      _.toArray(this.tinyEditorData.text).length > 20000

    this.includesScriptTagContent = checkScript(this.tinyEditorData.text)
    const valid = [
      // this.includesEmoji,
      this.overLengthContent,
      this.includesScriptTagContent
    ].every(item => !item)
    this.$emit('validContent', valid)
  }

  getEditorData(data: ITinyEditorData) {
    if (this.selectedMode === EEditMode.HTML) {
      this.tinyEditorData.html = data.html
      this.tinyEditorData.text = data.text
      this.tinyEditorData.fileIds = data.fileIds
    }
  }

  /**
   * Handle long text
   */
  handleLongText(text: string) {
    return PreviewText.covertToPreviewText(text ? text : '')
  }
}
