var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-comment-area"},[_c('TextAreaWithOneModeText',{ref:"CommentTextArea",attrs:{"title":_vm.title ? _vm.title : '',"titleSuffix":_vm.titleSuffix,"defaultValue":{
      html: _vm.newComment.content_comment,
      text: _vm.newComment.content_comment_text,
      fileIds: _vm.newComment.tiny_file_ids
    },"tinyFileType":_vm.eTinyFileType.TOPIC_COMMENT,"maxFileSize":_vm.maxFileSize,"textAreaId":_vm.textAreaId},on:{"submit":_vm.hanldeNewComment,"getValue":_vm.handleValueComment,"validContent":_vm.handleValidContent}}),_c('div',{class:{ 'd-flex justify-content-between': _vm.isCommentCmt }},[_c('div',{staticClass:"up-file py-2",class:{ 'w-75': _vm.isCommentCmt }},[_c('div',{staticClass:"file-chooser d-flex justify-content-start align-items-center"},[_c('div',{staticClass:"btn btn-outline-secondary-deep position-relative px-3 flex-fixed flex-center minh---30 rounded-pill"},[_c('input',{staticClass:"position-absolute form-control h-100 w-100 top-0 start-0 opacity-0",attrs:{"multiple":"","type":"file"},on:{"click":function (e) { return (e.target.value = null); },"change":_vm.onCommentFileChange}}),_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_choose_file'))+" ")]),_c('div',{staticClass:"text-line-clamp-1 ps-3",class:{
            'text-primary cursor-pointer': _vm.newComment.attach_file.length
          },on:{"click":_vm.openDisplayFiles}},[_vm._v(" "+_vm._s(_vm.commentFileName)+" ")])]),_c('div',{staticClass:"fs-8 fs-xl-10 py-1"},[_vm._v(" "+_vm._s(_vm.$t('common.form.file_modified_with_max', { max: _vm.maxFileSize.mb }))+" ")])]),(_vm.isCommentCmt)?_c('div',{staticClass:"comment-btn flex-center py-3"},[_c('button',{staticClass:"btn btn-primary px-4 w--27 button-border-radius",attrs:{"disabled":(!_vm.newComment.content_comment.trim() || !_vm.valid) &&
            !_vm.newComment.attach_file.length},on:{"click":_vm.hanldeNewComment}},[_vm._v(" "+_vm._s(_vm.$t('groups.nav_button.comment'))+" ")])]):_vm._e()]),(!_vm.isCommentCmt)?_c('div',{staticClass:"comment-btn flex-center py-3"},[_c('button',{staticClass:"btn btn-primary button-border-radius",attrs:{"disabled":(!_vm.newComment.content_comment.trim() || !_vm.valid) &&
          !_vm.newComment.attach_file.length},on:{"click":_vm.hanldeNewComment}},[_vm._v(" "+_vm._s(_vm.$t('groups.nav_button.comment'))+" ")])]):_vm._e(),_c('ModalInfo',{attrs:{"id":("modal-info-topic-comment-area-" + _vm.title),"infoMess":_vm.modalMess,"autoClose":false}}),_c('ModalTopicSelectedFile',{attrs:{"id":("modal-slelected-file-comment-area-" + _vm.title),"fileChooses":_vm.newComment.attach_file},on:{"remove":_vm.removeFileFromFilesModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }